import {Domains} from './domains';
import {Path} from './path';
import type {Role} from '../Users/Permission';

export type InternationalCoachingFunnelStage =
	| 'all'
	| 'discover'
	| 'apply'
	| 'accepted'
	| 'visa'
	| 'arrive'
	| 'placed'
	| 'archived';

export type DomesticCoachingFunnelStage =
	| 'all'
	| 'preConsult'
	| 'consult'
	| 'decision'
	| 'handoff'
	| 'archived';

type InternationalCoachingPrefix =
	`/coaching/${InternationalCoachingFunnelStage}`;
type DomesticCoachingPrefix =
	`/coaching-domestic/${DomesticCoachingFunnelStage}`;
type AdminInternationalCoachingPrefix =
	`/coaching/${string}/${InternationalCoachingFunnelStage}`;
type AdminDomesticCoachingPrefix =
	`/coaching-domestic/${string}/${DomesticCoachingFunnelStage}`;
export type StudentsViewPrefix =
	| '/students/all'
	| '/students/profile_complete'
	| '/students/verified';

export type UsersPrefix = '/users';

export type UserProfilePathPrefix =
	| InternationalCoachingPrefix
	| DomesticCoachingPrefix
	| AdminInternationalCoachingPrefix
	| AdminDomesticCoachingPrefix
	| StudentsViewPrefix
	| UsersPrefix;

// eslint-disable-next-line max-params
function userProfilePath(
	id: string,
	prefix: UserProfilePathPrefix,
	suffix: string,
	name?: string | null,
	type?: Role,
) {
	const domain = type === 'admin' ? Domains.admin : Domains.coach;
	return new Path(`${prefix}/user/${id}-${name}/${suffix}`, domain).url();
}

export const USER_PATHS = {
	overview: {
		index: new Path(
			(
				prefix: UserProfilePathPrefix,
				id: string,
				name?: string | null,
				type?: Role,
			) => userProfilePath(id, prefix, 'overview', name, type),
		),
		programRecommendations: {
			index: new Path(
				(
					prefix: UserProfilePathPrefix,
					id: string,
					name?: string | null,
					type?: Role,
				) => userProfilePath(id, prefix, 'recommendations', name, type),
			),
			add: new Path(
				(
					prefix: UserProfilePathPrefix,
					id: string,
					name?: string | null,
					type?: Role,
				) =>
					userProfilePath(
						id,
						prefix,
						'recommendations/add-recommendations',
						name,
						type,
					),
			),
		},
		documents: new Path(
			(
				prefix: UserProfilePathPrefix,
				id: string,
				name?: string | null,
				type?: Role,
			) => userProfilePath(id, prefix, 'documents', name, type),
		),
		applications: {
			index: new Path(
				(
					prefix: UserProfilePathPrefix,
					id: string,
					name?: string | null,
					type?: Role,
				) => userProfilePath(id, prefix, 'applications', name, type),
			),
			show: new Path(
				(
					prefix: UserProfilePathPrefix,
					id: string,
					applicationId: string,
					name?: string | null,
					type?: Role,
					// eslint-disable-next-line max-params
				) =>
					userProfilePath(
						id,
						prefix,
						`applications/${applicationId}`,
						name,
						type,
					),
			),
		},
		visa: new Path(
			(
				prefix: UserProfilePathPrefix,
				id: string,
				name?: string | null,
				type?: Role,
			) => userProfilePath(id, prefix, 'visa', name, type),
		),
		survey: new Path(
			(
				prefix: UserProfilePathPrefix,
				id: string,
				name?: string | null,
				type?: Role,
			) => userProfilePath(id, prefix, 'survey', name, type),
		),
	},
	students: {
		all: new Path((type?: Role) => {
			if (type === 'admin') {
				return new Path(`/students/all`, Domains.admin).url();
			} else if (type === 'coach') {
				return new Path(`/students/all`, Domains.coach).url();
			}

			return new Path('/').url();
		}),
		profileComplete: new Path((type?: Role) => {
			if (type === 'admin') {
				return new Path(`/students/profile_complete`, Domains.admin).url();
			} else if (type === 'coach') {
				return new Path(`/students/profile_complete`, Domains.coach).url();
			}

			return new Path('/').url();
		}),
		verified: new Path((type?: Role) => {
			if (type === 'admin') {
				return new Path(`/students/verified`, Domains.admin).url();
			} else if (type === 'coach') {
				return new Path(`/students/verified`, Domains.coach).url();
			}

			return new Path('/').url();
		}),
	},
	coaching: {
		domestic: {
			all: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(`/coaching-domestic/${id}/all`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching-domestic/all`, Domains.coach).url();
				}

				return new Path('/').url();
			}),
			preConsult: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(
						`/coaching-domestic/${id}/preConsult`,
						Domains.admin,
					).url();
				} else if (type === 'coach') {
					return new Path(`/coaching-domestic/preConsult`, Domains.coach).url();
				}

				return new Path('/').url();
			}),
			consult: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(
						`/coaching-domestic/${id}/consult`,
						Domains.admin,
					).url();
				} else if (type === 'coach') {
					return new Path(`/coaching-domestic/consult`, Domains.coach).url();
				}

				return new Path('/').url();
			}),
			decision: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(
						`/coaching-domestic/${id}/decision`,
						Domains.admin,
					).url();
				} else if (type === 'coach') {
					return new Path(`/coaching-domestic/decision`, Domains.coach).url();
				}

				return new Path('/').url();
			}),
			handOff: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(
						`/coaching-domestic/${id}/handoff`,
						Domains.admin,
					).url();
				} else if (type === 'coach') {
					return new Path(`/coaching-domestic/handoff`, Domains.coach).url();
				}

				return new Path('/').url();
			}),
			archived: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(
						`/coaching-domestic/${id}/archived`,
						Domains.admin,
					).url();
				} else if (type === 'coach') {
					return new Path(`/coaching-domestic/archived`, Domains.coach).url();
				}
				return new Path('/').url();
			}),
		},
		international: {
			all: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/all`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/all`, Domains.coach).url();
				}

				return new Path('/').url();
			}),
			getStarted: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/start`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/start`, Domains.coach).url();
				}
				return new Path('/').url();
			}),
			discover: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/discover`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/discover`, Domains.coach).url();
				}
				return new Path('/').url();
			}),
			prep: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/prep`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/prep`, Domains.coach).url();
				}
				return new Path('/').url();
			}),
			apply: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/apply`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/apply`, Domains.coach).url();
				}
				return new Path('/').url();
			}),
			accepted: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/accepted`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/accepted`, Domains.coach).url();
				}
				return new Path('/').url();
			}),
			visa: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/visa`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/visa`, Domains.coach).url();
				}
				return new Path('/').url();
			}),
			arrive: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/arrive`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/arrive`, Domains.coach).url();
				}
				return new Path('/').url();
			}),
			placed: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/placed`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/placed`, Domains.coach).url();
				}
				return new Path('/').url();
			}),
			archived: new Path((id: string, type?: Role) => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/archived`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/archived`, Domains.coach).url();
				}
				return new Path('/').url();
			}),
		},
	},
};
