import {seoURL} from '@halp/util';
import {Domains} from './domains';
import {Path} from './path';

export const ADMIN_PATHS = {
	index: new Path('/', Domains.admin),
	qualification: {
		index: new Path('/qualification', Domains.admin),
		show: new Path((id: number) => {
			return `/qualification/${id}`;
		}, Domains.admin),
	},
	survey: {
		index: new Path('/surveys', Domains.admin),
		survey: new Path((id: number | string) => {
			return `/surveys/${id}/survey`;
		}, Domains.admin),
		results: new Path((id: number | string) => {
			return `/surveys/${id}/results`;
		}, Domains.admin),
	},
	users: {
		index: new Path('/users', Domains.admin),
	},
	coaches: {
		index: new Path('/coaches', Domains.admin),
		show: new Path((id: number | string, seo?: string | null) => {
			return `/coaches/show/${id}${seoURL(id, seo)}`;
		}, Domains.admin),
	},
	campaigns: {
		index: new Path('/campaigns', Domains.admin),
		show: new Path((id: number | string, seo?: string | null) => {
			return `/campaigns/${id}${seoURL(id, seo)}`;
		}, Domains.admin),
	},
	ambassadors: {
		index: new Path('/ambassadors', Domains.admin),
		show: new Path((id: number | string, seo?: string | null) => {
			return `/ambassadors/${id}${seoURL(id, seo)}`;
		}, Domains.admin),
		invited: new Path('/ambassadors/invited', Domains.admin),
		legacy: new Path('/ambassadors/legacy', Domains.admin),
	},
	coaching: {
		index: new Path((coachingType: 'domestic' | 'international') => {
			return coachingType === 'domestic' ? `/coaching-domestic` : `/coaching`;
		}, Domains.admin),
	},
};
