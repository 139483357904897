import {
	faChalkboardTeacher,
	faChartLine,
	faUserGraduate,
	faUsers,
	faIdCard,
	faEarthAmericas,
	faBook,
	faHandshake,
	faClipboardQuestion,
	faBullhorn,
	faSquarePollHorizontal,
} from '@fortawesome/free-solid-svg-icons';
import {SidebarNav} from '@halp/ui';
import {Paths} from '@halp/foundation/Paths';
import {AdminUserMenu} from '@halp/foundation/Users';
import {imgLogoWhite} from '@halp/images';

export function SideNav({
	setOpenMenu,
}: {
	mobile?: boolean;
	setOpenMenu?: (open: boolean) => void;
}) {
	const items = [
		{text: 'Charts', path: Paths.admin.index.url(), icon: faChartLine},
		{type: 'heading', text: 'Dashboard'} as const,
		{
			text: 'Coach Students',
			path: Paths.admin.coaching.index.url('domestic'),
			icon: faChalkboardTeacher,
		},
		{
			text: 'Qualification',
			path: Paths.admin.qualification.index.url(),
			icon: faEarthAmericas,
		},
		{
			text: 'Surveys',
			path: Paths.admin.survey.index.url(),
			icon: faSquarePollHorizontal,
		},
		{
			text: 'Campaigns',
			path: Paths.admin.campaigns.index.url(),
			icon: faBullhorn,
		},
		{type: 'heading', text: 'Users'} as const,
		{
			text: 'Students',
			path: Paths.users.students.verified.url('admin'),
			icon: faUserGraduate,
		},
		{
			text: 'Ambassadors',
			path: Paths.admin.ambassadors.index.url(),
			icon: faHandshake,
		},
		{
			text: 'Coaches',
			path: Paths.admin.coaches.index.url(),
			icon: faIdCard,
		},
		{text: 'Users', path: Paths.admin.users.index.url(), icon: faUsers},
		{type: 'heading', text: 'Resources'} as const,
		{
			text: 'Handbook',
			path: Paths.coach.handbook,
			icon: faClipboardQuestion,
			target: '_blank',
		},
		{
			text: 'Programs',
			path: Paths.programs.programs.index.url('admin'),
			icon: faBook,
		},
		{type: 'break'} as const,
	];

	return (
		<SidebarNav logo={imgLogoWhite} items={items} toggle={setOpenMenu}>
			<AdminUserMenu />
		</SidebarNav>
	);
}
